<template>
  <div class="row">
    <div class="col-md-12">
      <card class="stacked-form">
        <form @submit.prevent="submit">
          <!-- form -->
          <div class="row">
            <div class="col-12">
              <base-input label="Nome do Banco" v-model="form.name"></base-input>
            </div>

            <div class="col-12">
              <base-input label="Código do Banco" v-model="form.code" placeholder="Ex: 104"></base-input>
            </div>

            <div class="col-12 mt-4">
              <label>Logo </label>
              <div class="upload-container">
                <div class="upload-preview" :style="'background: url(' + form.logo + ')'" v-if="form.logo !== '' && form.logo !== null">
                  <div class="remove-file" @click="form.logo = ''">
                    <p><i class="fa fa-times"></i></p>
                    <p>Remover</p>
                  </div>
                </div>
                <div class="add-file">
                  <label for="image" v-if="!(form.logo !== '' && form.logo !== null)">
                    Selecionar <span class="tim-icons icon-upload"></span>
                  </label>
                  <label for="image" v-if="form.logo !== '' && form.logo !== null">
                    Alterar <span class="tim-icons icon-pencil"></span>
                  </label>
                  <input @change.prevent="addLogo" type="file" id="image" accept="image/png, image/jpeg, image/jpg" />
                </div>
              </div>
            </div>

            <div class="col-12">
              <label class="mr-3">Status:</label>
              <el-radio-group v-model="form.status">
                <el-radio class="mb-0" :label="'1'">Ativo</el-radio>
                <el-radio class="mb-0" :label="'0'">Inativo</el-radio>
              </el-radio-group>
            </div>

            <!-- botoes -->
            <div class="col-12 mt-3">
              <base-button @click.prevent.stop="goBack()" type="danger">Cancelar</base-button>
              <base-button class="ml-3" native-type="submit" type="success">Salvar</base-button>
            </div>
          </div>
        </form>
      </card>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import swal from 'sweetalert2'
import vSelect from 'vue-select'
Vue.component('v-select', vSelect)
export default {
  data: () => ({
    form: {
      status: 0,
      logo: '',
      name: '',
      code: ''
    },
    types: []
  }),
  methods: {
    async getData () {
      const id = this.$route.params.id || false
      if (id) {
        this.form = (await this.$http.get(`banks/${id}`)).data.bank
      }
    },
    addLogo (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      const vue = this
      const file = files[0]
      const reader = new FileReader()

      reader.onload = e => {
        vue.form.logo = e.target.result
      }
      reader.readAsDataURL(file)
    },
    async submit () {
      if (this.validate()) {
        const id = this.$route.params.id || false
        let status
        try {
          if (id) {
            status = (await this.$http.put(`banks/${id}`, this.form)).status
          }
          if (!id) status = (await this.$http.post('banks', this.form)).status
        } catch (e) {
          status = e.response.status
        }

        if (status === 200) {
          await swal.fire({
            title: 'Salvo!',
            text: `"${this.form.name}" salvo com sucesso.`,
            showConfirmButton: false,
            timer: 1000
          })
          this.goBack()
        } else {
          await swal.fire({
            title: 'Erro!',
            text: 'Ocorreu um erro, tente novamente mais tarde.',
            denyButtonText: 'Ok',
            showDenyButton: true,
            showConfirmButton: false
          })
        }
      } else {
        await swal.fire({
          title: 'Erro!',
          text: 'Por favor, preencha todos os campos obrigatórios.',
          denyButtonText: 'Ok',
          showDenyButton: true,
          showConfirmButton: false
        })
      }
    },
    goBack () {
      window.history.go(-1)
    },
    validate () {
      const form = this.form
      if (!form.name) return false
      if (!form.code) return false
      return true
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
